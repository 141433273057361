.home-work-text-banner {
  max-height: 240px;
  overflow: hidden;
  .marquee-text {
    z-index: -111;
    color: light-neutral(80);
    font-size: 300px;
    line-height: 300px;
    font-family: PP Neue Machina;
    letter-spacing: -15px;
    user-select: none;

    &-bold {
      color: light-neutral(90);
      font-weight: 800;
    }
  }

  .rfm-marquee {
    z-index: auto;
  }
}

@media #{$media-2xl} {
  .home-work-text-banner {
    max-height: 224px;
    .marquee-text {
      font-size: 280px;
      line-height: 280px;

      &-bold {
        font-weight: 800;
      }
    }
  }
}

@media #{$media-xl} {
  .home-work-text-banner {
    max-height: 192px;
    .marquee-text {
      font-size: 240px;
      line-height: 240px;

      &-bold {
        font-weight: 800;
      }
    }
  }
}

@media #{$media-lg} {
  .home-work-text-banner {
    max-height: 161px;
    .marquee-text {
      font-size: 200px;
      line-height: 200px;

      &-bold {
        font-weight: 800;
      }
    }
  }
}

@media #{$media-sm} {
  .home-work-text-banner {
    max-height: 112px;
    .marquee-text {
      font-size: 140px;
      line-height: 140px;
      letter-spacing: normal;
      &-bold {
        font-weight: 800;
      }
    }
  }
}

@media #{$media-es} {
  .home-work-text-banner {
    max-height: 80px;
    .marquee-text {
      font-size: 100px;
      line-height: 100px;
      letter-spacing: normal;
      &-bold {
        font-weight: 800;
      }
    }
  }
}
