.careers {
  &__form {
    &_group {
      border-bottom: 1px solid dark-neutral(0);
      padding: 16px 0;
      position: relative;
      overflow: hidden;

      input[type='text'],
      input[type='tel'],
      textarea {
        width: 100%;
        padding: 15px;
        font-size: 45px;
        line-height: 65px;
        border: none !important;
        box-sizing: border-box;
        box-shadow: none !important;
        outline: none !important;
        background-color: transparent;
      }

      .error__message {
        position: absolute;
        bottom: -40px;
        right: 0;
        background-color: primary(500);
        font-size: 1.25vw;
        color: light-neutral(900);
        padding: 5px 15px;
        border-top-left-radius: 20px;
        border-top-right-radius: 3px;
        opacity: 0;
        transition: all, 0.4s ease-in-out;
        &--active {
          bottom: 0;
          opacity: 1;
        }
      }

      .send__group {
        position: relative;
        height: 80px;
        font-size: 45px;
        line-height: 80px;
        button[type='submit'] {
          width: 100%;
          height: 100%;
          border: 1px solid dark-neutral(0);
          background: primary(50) !important;
          outline: none !important;
          box-shadow: none !important;
          border-radius: 38px;
          position: absolute;
          right: 0;
          overflow: hidden;
          transition: all, 0.4s;
          .button-text {
            padding: 15px;
            box-sizing: border-box;
            font: normal normal normal 45px/55px PP Neue Machina;
            color: dark-neutral(0);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .c-icon {
              margin: 0;
            }
            .send__message {
              position: absolute;
              right: 15px;
              top: 55%;
              transform: translateY(-50%);
              transition: all, 0.4s;
              display: flex;
              align-items: center;
              .spinner {
                margin-left: 15px;
              }
            }
          }
        }
        .success {
          padding: 15px;
          box-sizing: border-box;
        }
        &--success {
          button[type='submit'] {
            width: 80px;
            .button-text {
              .c-icon {
                color: primary(500);
              }
              .send__message {
                right: -200px;
              }
            }
          }
        }
      }

      textarea {
        font-size: 25px;
        line-height: normal;
      }

      .upload {
        label {
          display: flex;
          align-items: center;
          font-size: 45px;
          line-height: 45px;
          cursor: pointer;
          span {
            font-size: 18px;
            margin-left: 5px;
          }
          .c-icon {
            position: relative;
            top: -3px;
            margin-left: 10px;
          }
        }
      }

      &.bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        font: normal normal normal 90px/110px PP Neue Machina;
        padding: 0;

        .title {
          flex-shrink: 0;
          white-space: nowrap;
          overflow: hidden;
          position: relative;
          &--v2 {
            color: primary(300);
          }
        }
      }

      &.e-mail,
      &.phone {
        padding: 0;

        input {
          padding: 15px 0;
        }
      }

      &.name {
        padding: 0;
        input {
          padding: 0 0 15px 0;
        }
      }

      &.cv {
        padding: 24px 0 15px 0;
      }

      &.tell-about {
        padding: 0;
        textarea {
          padding: 20px 0 105px 0;
        }
      }
    }
  }
}

@media #{$media-md} {
  .careers {
    &__form {
      &_group {
        input[type='text'],
        input[type='tel'],
        textarea {
          font-size: 25px;
          padding: 0 15px;
        }
        .col {
          padding: 15px !important;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .careers {
    &__form {
      &_group {
        padding: 5px 0;
        input[type='text'],
        input[type='tel'],
        textarea {
          font-size: 20px;
          padding: 5px;
          line-height: 35px;
        }
        .error__message {
          font-size: 3.25vw;
        }
        .col {
          padding: 15px !important;
        }
        .title {
          line-height: 50px;
          font-size: 10vw;
        }
        .send__group {
          height: 60px;
          font-size: 25px;
          line-height: 30px;
          button[type='submit'] {
            .button-text {
              padding: 0;
              font-size: 25px;
              line-height: 40px;
            }
          }
          &--success {
            button[type='submit'] {
              .button-text {
                padding: 0 15px;
              }
            }
          }
        }
      }
    }
  }
}
