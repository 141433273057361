.work-banner {
  height: 100%;
  width: 100%;

  .image {
    width: 100%;
    height: 100%;

    img {
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      height: 100%;
    }
  }

  &-video {
    position: relative;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
      object-position: center;
      height: 100%;
    }

    .video-icon-button {
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 58px;
      height: 58px;
      opacity: 0.8;
      cursor: pointer;

      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}

@media #{$media-lg} {
  .work-banner {
    &-video {
      .video-icon-button {
        width: 58px;
        height: 58px;
      }
    }
  }
}

@media #{$media-sm} {
  .work-banner {
    &-video {
      .video-icon-button {
        width: 48px;
        height: 48px;
      }
    }
  }
}
