.project-image {
  width: 100%;
  height: 900px;

  .video-icon-button {
    width: 104px !important;
    height: 104px !important;
    filter: drop-shadow(0 0 24px rgba(0, 0, 0, 1));
  }
}

.view-all {
  position: relative;
  padding-bottom: 10rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 37%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 66%,
      rgba(255, 255, 255, 1) 88%
    );
  }

  &-button {
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    bottom: 50px;
    left: 50%;
    color: #7e7e7e;
    font: normal normal normal 45px/55px PP Neue Machina;
    letter-spacing: -2.25px;
    border-bottom: 1px solid #7e7e7e;
    transition: all 250ms ease-in-out;

    &:hover {
      border-bottom: 12px solid #afafaf;
    }
  }

  // .minimized-content {
  //   max-height: 200px;
  //   overflow: hidden;
  // }
}

@media #{$media-sm} {
  .project-image {
    height: 250px;

    .video-icon-button {
      width: 48px !important;
      height: 48px !important;
    }
  }

  .view-all {
  padding-bottom: 4rem;

    &-button {
      bottom: 30px;
      font: normal normal normal 22px/31px PP Neue Machina;
      letter-spacing: -1.25px;
    }

    // .minimized-content {
    //   max-height: 100px;
    //   overflow: hidden;
    // }
  }
}
