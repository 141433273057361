.popup {
  width: 100%;
  height: 100%;
  inset: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  display: grid;
  place-content: center;
  overflow: hidden;
  z-index: 1000;

  .popup-media {
    position: relative;

    &-container {
      width: 80vw;
      height: 80vh;
      overflow: hidden;
      border-radius: 20px;
    }

    .image {
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
      }
    }

    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-cancel-button {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 0px;
      right: -50px;
      transform: translate(-50%, -50%);
      display: grid;
      place-content: center;
      border: none;
      background-color: transparent;
      background-color: #000;
      border-radius: 100%;
      cursor: pointer;
      border: 1px solid light-neutral(0);
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
      z-index: 1;

      .c-icon {
        font-size: 25px;
        color: light-neutral(0);
      }
    }
  }
}
