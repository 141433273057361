@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-InktrapLight.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-InktrapLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainRegularItalic.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-PlainRegularItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainUltrabold.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-PlainUltrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-InktrapLightItalic.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-InktrapLightItalic.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-InktrapRegularItalic.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-InktrapRegularItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-InktrapUltrabold.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-InktrapUltrabold.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-InktrapRegular.woff')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-InktrapRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-InktrapUltraboldItalic.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-InktrapUltraboldItalic.woff')
      format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainLightItalic.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-PlainLightItalic.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainRegular.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-PlainRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainLight.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-PlainLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Machina';
  src: url('/fonts/PPNeueMachina/PPNeueMachina-PlainUltraboldItalic.woff2')
      format('woff2'),
    url('/fonts/PPNeueMachina/PPNeueMachina-PlainUltraboldItalic.woff')
      format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('/fonts/PPNeueMachina/RedHatDisplay-Light.ttf') format('woff2'),
    url('/fonts/PPNeueMachina/RedHatDisplay-Light.ttf') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('/fonts/PPNeueMachina/RedHatDisplay-LightItalic.ttf') format('woff2'),
    url('/fonts/PPNeueMachina/RedHatDisplay-LightItalic.ttf') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('/fonts/PPNeueMachina/RedHatDisplay-Medium.ttf') format('woff2'),
    url('/fonts/PPNeueMachina/RedHatDisplay-Medium.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('/fonts/PPNeueMachina/RedHatDisplay-MediumItalic.ttf')
      format('woff2'),
    url('/fonts/PPNeueMachina/RedHatDisplay-MediumItalic.ttf') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('/fonts/PPNeueMachina/RedHatDisplay-Regular.ttf') format('woff2'),
    url('/fonts/PPNeueMachina/RedHatDisplay-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('/fonts/PPNeueMachina/RedHatDisplay-Bold.ttf') format('woff2'),
    url('/fonts/PPNeueMachina/RedHatDisplay-Bold.ttf') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('/fonts/PPNeueMachina/RedHatDisplay-BoldItalic.ttf') format('woff2'),
    url('/fonts/PPNeueMachina/RedHatDisplay-BoldItalic.ttf') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
