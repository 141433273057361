.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(light-neutral-rgb(900), 0) 0%,
    rgba(light-neutral-rgb(900), 1) 100%
  );
  z-index: 10;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0vh;
    background-color: #fff;
    pointer-events: none;
    transition: all, 0.4s ease-in-out;
  }

  .col {
    padding: 0;
    position: relative;
  }

  button {
    &.menu__button {
      background: transparent !important;
      box-shadow: none !important;
      outline: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      min-width: auto;
      min-height: auto;
      border: none;
      .button-text {
        display: flex;
        align-items: center;
        color: light-neutral(0);
        line-height: 22px;
        font-size: 17px;
        .c-icon {
          margin-left: 10px;
        }
      }
      &--mobile {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        .button-text {
          .c-icon {
            margin: 0;
          }
        }
      }
    }
  }

  &__top {
    position: relative;
    z-index: 2;
    &__menu {
      min-height: 35px;
      display: flex;
      align-items: center;

      .language-switch {
        margin-left: auto;
      }
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    .container {
      padding: 15px;
      border-top: 1px solid light-neutral(0);
      border-bottom: 1px solid light-neutral(0);
    }
    &__logo {
      figure {
        a {
          display: flex;
          align-items: center;
          outline: none !important;
          img {
            height: 100px;
          }
        }
      }
    }
    &__page {
      &__title {
        font: normal normal normal 100px/123px PP Neue Machina;
        position: relative;
        // font-size: 100px;
        // line-height: 100px;
        color: light-neutral(0);
        transition: all, 0.4s;
        opacity: 1;
        top: 10px;
        &--disable {
          opacity: 0;
        }
      }
    }
    &__menu {
      position: absolute;
      width: 100%;
      right: 0;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        li {
          padding: 0 40px;
          margin: 0;
          box-sizing: border-box;
          a {
            font-size: 65px;
            transition: all 250ms ease-in-out;
            line-height: 80px;
            display: block;
            text-align: center;
            color: light-neutral(0);
            &:hover {
              &.active {
                font-size: 85px;
              }
            }
          }
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
        &:hover {
          li {
            &:not(:hover) {
              a {
                font-size: 50px;
              }
            }
          }
        }
      }
      &--active {
        pointer-events: auto;
        animation-name: floatBubble;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
      }
      &:not(&--active) {
        top: -50px;
        opacity: 0;
        transform: translateY(0);
        pointer-events: none;
        max-height: 0;
      }
    }
  }

  &__contacts {
    padding: 15px 0;
    position: relative;
    top: -100px;
    opacity: 0;
    pointer-events: none;
    transition: all, 0.4s ease-in-out;
    color: #000;
    max-height: 0;
    overflow: hidden;
    &__item {
      margin: 15px 0;
      &.social__list {
        display: flex;
        .social__list__item {
          margin: 0 10px;
          display: block;
        }
      }
    }
    &--active {
      top: 0;
      opacity: 1;
      pointer-events: auto;
      max-height: 200px;
    }
  }

  &--gradient {
  }
}

@media only screen and (max-width: 1919px) {
  .header {
    &__body {
      &__page {
        &__title {
          font-size: 50px;
        }
      }
      &__menu {
        ul {
          li {
            a {
              font-size: 50px;
              line-height: 50px;
              &:hover {
                &.active {
                  font-size: 75px;
                }
              }
            }
          }
          &:hover {
            li {
              &:not(:hover) {
                a {
                  font-size: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .header {
    &__body {
      &__page {
        &__title {
          font-size: 50px;
        }
      }
      &__menu {
        ul {
          li {
            a {
              font-size: 34px;
              line-height: 40px;
              &:hover {
                &.active {
                  font-size: 50px;
                }
              }
            }
          }
          &:hover {
            li {
              &:not(:hover) {
                a {
                  font-size: 35px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .header {
    &__body {
      &__page {
        &__title {
          font-size: 45px;
        }
      }
      &__menu {
        ul {
          li {
            padding: 0 20px;
            a {
              font-size: 35px;
              line-height: 40px;
              &:hover {
                &.active {
                  font-size: 60px;
                }
              }
            }
          }
          &:hover {
            li {
              &:not(:hover) {
                a {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .header {
    &__body {
      &__logo {
        display: flex;
        align-items: center;
        figure {
          a {
            img {
              height: 55px;
            }
          }
        }
      }
      &__page {
        &__title {
          top: 5px;
          font-size: 30px;
          line-height: 50px;
          margin-left: 25px;
        }
      }
      &__menu {
        position: relative;
        transform: translateY(0) !important;
        overflow: hidden;
        margin-top: 0;
        transition: all, 0.4s ease-in-out;
        ul {
          flex-direction: column;
          li {
            width: 100%;
            padding: 0;
            a {
              padding: 15px 0;
              text-align: left;
              font-size: 50px !important;
              line-height: 50px;
            }
            &:first-child {
              margin-top: 25px;
            }
          }
        }
      }
    }
    &--menu-active {
      &::before {
        height: 100vh;
      }

      button {
        &.menu__button {
          .button-text {
            color: #000;
          }
        }
      }

      .header {
        &__body {
          .container {
            border-color: #000;
          }
          &__logo {
            figure {
              img {
                -webkit-filter: invert(100%);
                filter: invert(100%);
              }
            }
          }
          &__menu {
            margin-top: 30px;
            ul {
              li {
                a {
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .header {
    &__body {
      &__logo {
        display: flex;
        align-items: center;
        figure {
          a {
            img {
              height: 55px;
            }
          }
        }
      }
      &__page {
        &__title {
          font: normal normal normal 35px/43px PP Neue Machina;
          top: 5px;
          // font-size: 30px;
          // line-height: 50px;
          margin-left: 25px;
        }
      }
      &__menu {
        position: relative;
        transform: translateY(0) !important;
        overflow: hidden;
        margin-top: 0;
        transition: all, 0.4s ease-in-out;
        ul {
          flex-direction: column;
          li {
            width: 100%;
            padding: 0;
            position: relative;
            overflow: hidden;
            animation-name: reversefloatBubbleMobile;
            animation-duration: 0.8s;
            animation-fill-mode: forwards;
            a {
              padding: 15px 0;
              text-align: left;
              font-size: 50px !important;
              line-height: 50px;
            }
            &:first-child {
              margin-top: 25px;
            }
          }
        }
        &--active {
          ul {
            li {
              animation-name: floatBubbleMobile;
              animation-duration: 0.8s;
              animation-fill-mode: forwards;
            }
          }
        }
      }
    }
    &--menu-active {
      &::before {
        height: 100vh;
      }

      button {
        &.menu__button {
          .button-text {
            color: #000;
          }
        }
      }

      .header {
        &__body {
          .container {
            border-color: #000;
          }
          &__logo {
            figure {
              img {
                -webkit-filter: invert(100%);
                filter: invert(100%);
              }
            }
          }
          &__menu {
            margin-top: 30px;
            ul {
              li {
                a {
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes floatBubble {
  0% {
    top: -100px;
    max-height: 0;
  }

  50% {
    top: 55%;
    opacity: 1;
    max-height: 1000px;
  }

  100% {
    top: 50%;
    opacity: 1;
    transform: translateY(-45%);
    pointer-events: auto;
  }
}

@keyframes reverseFloatBubble {
  0% {
    top: 50%;
    opacity: 1;
    transform: translateY(-45%);
    max-height: 1000px;
  }

  100% {
    top: -50px;
    opacity: 0;
    transform: translateY(0);
    pointer-events: none;
    max-height: 0;
  }
}

@keyframes floatBubbleMobile {
  0% {
    top: -25px;
    max-height: 0;
  }

  50% {
    top: 0;
    margin-top: 15px;
    max-height: 100px;
  }

  100% {
    top: 0;
    margin-top: 0px;
    pointer-events: auto;
  }
}

@keyframes reversefloatBubbleMobile {
  0% {
    top: 0;
    max-height: 100px;
    pointer-events: auto;
  }

  50% {
    top: -25px;
  }

  100% {
    top: -25px;
    max-height: 0;
  }
}
