.headline-work-list {
  margin: 2rem 0;
}

.headline-worklist-header {
  &-title {
    font: normal normal normal 90px/90px PP Neue Machina;
  }
}

.headline-worklist-category {
  position: relative;
  border-top: 2px solid dark-neutral(300);
  border-bottom: 1px solid dark-neutral(300);
  padding: 1.5rem 0;
  align-items: center;

  .category {
    display: flex;
    justify-content: flex-start;

    &-item {
      font: normal normal normal 45px/55px PP Neue Machina;
      letter-spacing: -2.25px;
      color: dark-neutral(80);
      width: max-content;
      white-space: nowrap;

      &:not(:last-of-type)::after {
        content: '/';
        display: inline-block;
        margin: 0 10px;
      }
    }
  }

  &-desc {
    font: normal normal 600 18px/30px Red Hat Display;
    color: dark-neutral(80);
    margin-left: 3rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
}

.headline-worklist-property {
  position: relative;
  border-top: 1px solid dark-neutral(300);
  border-bottom: 2px solid dark-neutral(300);
  padding: 1.5rem 0;
}

.headline-worklist-desc {
  font: normal normal 600 18px/30px Red Hat Display;
  color: dark-neutral(80);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* En fazla gösterilecek satır sayısı */
}

.headline-worklist-category > .c-icon,
.headline-worklist-property > .c-icon {
  position: absolute;
  width: fit-content;
  right: 0;
  top: 0.5rem;
  color: dark-neutral(80);
}

.result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 24px;
  margin-top: 0.5rem;

  &-number {
    font: normal normal normal 38px/44px PP Neue Machina;
    color: dark-neutral(80);
  }

  &-title {
    color: dark-neutral(80);
    font: normal normal normal 38px/44px PP Neue Machina;
  }

  &:nth-child(2) {
    justify-content: center;
  }
  &:nth-child(3) {
    justify-content: flex-end;
  }
}

@media #{$media-xl} {
  .result {
    flex-direction: column;
  }

  .headline-worklist-category-desc {
    display: none;
  }
}

@media #{$media-lg} {
  .result {
    flex-direction: column;
  }

  .headline-worklist-category-desc {
    display: none;
  }
}

@media #{$media-sm} {
  .headline-worklist-header {
    &-title {
      font: normal normal normal 28px/42px PP Neue Machina;
    }
  }

  .headline-worklist-category,
  .headline-worklist-property {
    position: relative;
    padding: 0.7rem 0;
  }

  .headline-worklist-category {
    .category {
      &-item {
        font: normal normal normal 15px/18px PP Neue Machina;
        letter-spacing: -0.75px;
      }
    }

    .c-icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .headline-worklist-property {
    display: flex;
    justify-content: flex-start;

    .col {
      max-width: 85%;

      .row{
      
       .col{
        text-align: center;
       }
      }
    }
  }

  .result {
    flex-direction: column;
    margin-top: 0;
    padding: 0;

    &-number {
      font: normal normal 600 15px/18px Red Hat Display;
      letter-spacing: 0.3px;
    }

    &-title {
      font: normal normal 600 15px/18px Red Hat Display;
      letter-spacing: 0.3px;
    }
  }
}
