.awards {
  margin: 25px 0;
  .title {
    font-size: 45px;
  }
  &__item {
    border-bottom: 1px solid light-neutral(0);
    font-family: Red Hat Display;
    padding: 28px 0;
    font-size: 25px;
    &:first-child {
      border-top: 1px solid light-neutral(0);
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

@media #{$media-sm} {
  .awards {
    .title {
      font-size: 24px;
    }
    &__item {
      .col {
        font-size: 15px;
        padding: 7.5px 15px;
        justify-content: flex-start;
        font-size: 15px;
      }
    }
  }
}
