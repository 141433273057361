@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon/icomoon.eot');
  src: url('/fonts/icomoon/icomoon.eot') format('embedded-opentype'),
    url('/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('/fonts/icomoon/icomoon.woff') format('woff'),
    url('/fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-approval:before {
  content: '\e900';
}
.icon-C:before {
  content: '\e901';
}
.icon-directions:before {
  content: '\e902';
}
.icon-down:before {
  content: '\e903';
}
.icon-facebook:before {
  content: '\e904';
}
.icon-filter:before {
  content: '\e905';
}
.icon-info:before {
  content: '\e906';
}
.icon-insta:before {
  content: '\e907';
}
.icon-linkedin:before {
  content: '\e908';
}
.icon-manu:before {
  content: '\e909';
}
.icon-send:before {
  content: '\e90a';
}
.icon-statistics:before {
  content: '\e90b';
}
.icon-upload:before {
  content: '\e90c';
}
.icon-x:before {
  content: '\e90d';
}
.icon-youtube:before {
  content: '\e90e';
}
.icon-sphere:before {
  content: '\e9cc';
}
.icon-cancel-circle:before {
  content: '\ea0d';
}
.icon-close:before {
  content: '\e939';
}
