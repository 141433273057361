.team {
  &__member {
    &__detail {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 25px;
        font-family: $font-family-2;
        span {
          display: block;
          font-size: 18px;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .team {
    &__member {
      &__detail {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 15px;
          font-family: $font-family-2;
          span {
            display: block;
            font-size: 12px;
          }
        }
      }
    }
  }
}
