.our__partners {
  margin: 25px 0;
  .title {
    font-size: 45px;
    border-bottom: 1px solid light-neutral(100);
  }
  &__item {
    border-bottom: 1px solid light-neutral(100);
    padding: 28px 0;
    font-size: 25px;
    font-family: Red Hat Display;
    font-weight: normal;
    .col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .c-icon {
        margin-right: 15px;
      }
      &:first-child {
        margin-right: auto;
        justify-content: flex-start;
      }
    }
    &:first-child {
      border-top: 1px solid light-neutral(100);
    }
  }
}

@media #{$media-sm} {
  .our__partners {
    .title {
      font-size: 24px;
    }
    &__item {
      .col {
        font-size: 15px;
        padding: 7.5px 15px;
        justify-content: flex-start;
        &:first-child {
          font-size: 20px;
        }
      }
    }
  }
}
