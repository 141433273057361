.headline-top {
  position: relative;
  border-top: 2px solid dark-neutral(300);
  border-bottom: 1px solid dark-neutral(300);
  padding: 2rem 0;
  margin-top: 2rem;
}

.headline-bottom {
  position: relative;
  border-top: 1px solid dark-neutral(300);
  border-bottom: 2px solid dark-neutral(300);
  padding: 2rem 0;
  margin-bottom: 2rem;
}

.headline-title {
  font: normal normal normal 45px/55px PP Neue Machina;
}

.headline-desc {
  font: normal normal normal 22px/26px Red Hat Display;
}

.headline-top > .c-icon,
.headline-bottom > .c-icon {
  position: absolute;
  width: fit-content;
  right: 0;
  top: 1.5rem;
  color: dark-neutral(70);
}

.result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 24px;
  margin-top: 0.5rem;

  &-number {
    font: normal normal normal 58px/64px PP Neue Machina;
    letter-spacing: -3.25px;
  }

  &-title {
    color: dark-neutral(80);
    font: normal normal normal 38px/44px PP Neue Machina;
  }

  &:nth-child(2) {
    justify-content: center;
  }
  &:nth-child(3) {
    justify-content: flex-end;
  }
}

@media #{$media-lg} {
  .result {
    flex-direction: column;
  }
}

@media #{$media-sm} {
  .headline-top,
  .headline-bottom {
    position: relative;
    padding: 1.5rem 0;
  }

  .headline-title {
    font: normal normal normal 30px/37px PP Neue Machina;
  }

  .headline-desc {
    font: normal normal normal 15px/27px Red Hat Display;
  }

  .result {
    flex-direction: column;
    margin-top: 0;

    &-number {
      font: normal normal normal 25px/31px PP Neue Machina;
      letter-spacing: -1.25px;
    }

    &-title {
      font: normal normal 600 15px/20px Red Hat Display;
      letter-spacing: 0.3px;
    }
  }
}
