.work-detail {
  background-color: light-neutral(0);
  .image {
    width: 100%;
  }

  .container {
    padding: 0;
  }

  .col {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
