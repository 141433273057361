.hero {
  height: 100vh;
  &__item {
    height: 100vh;
    position: relative;

    figure {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        inset: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .image {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__detail {
      position: absolute;
      width: 100%;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      padding: 65px 15px;
      color: light-neutral(0);
      .title {
        font: normal normal normal 70px/120px PP Neue Machina;
        border-top: 1px solid light-neutral(0);
        border-bottom: 1px solid light-neutral(0);
        margin-top: 24px;
      }
      .desc {
        font-size: 18px;
        font-family: $font-family-2;
        line-height: 30px;
      }
    }
  }
  .hero__dots {
    position: absolute;
    right: 5%;
    bottom: 0;
    z-index: 1;
    padding: 65px 15px;
    display: flex;
    align-items: flex-end;
    .hero__dot {
      padding: 0;
      button {
        padding: 0 5px;
        min-width: 1px;
        box-shadow: none;
        outline: none;
        background: transparent;
        &::before {
          content: '';
          height: 40px;
          width: 1px;
          background-color: light-neutral(0);
        }
      }
      &--active {
        button {
          &::before {
            height: 111px;
          }
        }
      }
      &:last-child {
        button {
          padding-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .hero {
    height: 100vh;
    &__item {
      height: 100vh;
      figure {
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__detail {
        .title {
          font: normal normal normal 50px/110px PP Neue Machina;
        }

        .desc {
          font: normal normal normal 15px/20px Red Hat Display;
        }
      }
    }
  }
}

@media #{$media-md} {
  .hero {
    height: 100vh;
    &__item {
      height: 100vh;
      figure {
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__detail {
        .title {
          font: normal normal normal 1.5 PP Neue Machina;
        }

        .desc {
          font: normal normal normal 15px/20px Red Hat Display;
        }
      }
    }
    &__dot {
      &--active {
        button {
          &::before {
            height: 90px !important;
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .hero {
    height: 100vh;
    &__item {
      height: 100vh;
      figure {
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__detail {
        .title {
          font: normal normal normal 19px/40px PP Neue Machina;
        }

        .desc {
          font: normal normal normal 15px/20px Red Hat Display;
        }
      }
    }
    &__dot {
      &--active {
        button {
          &::before {
            height: 90px !important;
          }
        }
      }
    }
  }
}
