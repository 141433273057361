.addresses {
  margin-top: 3rem;
  .adress {
    &-image {
      position: relative;
      width: 100%;

      & > div {
        width: 100%;
      }

      img {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
      }

      iframe {
        border-radius: 25px;
        overflow: hidden;
        border: 1px solid light-neutral(0);
        box-sizing: border-box;
        width: 98%;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 350px;
      overflow: auto;
      row-gap: 12px;

      &-title {
        font: normal normal normal 45px/50px PP Neue Machina;
        margin-bottom: 0;
      }
      &-adress {
        width: 60%;
        font: normal normal normal 25px/33px Red Hat Display;
      }
      &-phone {
        font: normal normal normal 25px/33px Red Hat Display;
      }
      &-mail {
        font: normal normal normal 25px/33px Red Hat Display;
      }
      &-get-directions {
        font: normal normal 600 25px/33px Red Hat Display;

        .c-icon {
          margin-left: 12px;
        }
      }
    }
  }

  &.single{
    .adress{
    display:flex;
    flex-direction: row;

      &-image {
        flex: 1;
      }
    }
  }
}

@media #{$media-xl} {
  .addresses {
    .adress {
      &-content {
        margin-top: 2rem;
        min-height: 225px;

        &-title {
          font: normal normal normal 25px/31px PP Neue Machina;
        }
        &-adress {
          width: 60%;
          font: normal normal normal 15px/20px Red Hat Display;
        }
        &-phone {
          font: normal normal normal 15px/20px Red Hat Display;
        }
        &-mail {
          font: normal normal normal 15px/20px Red Hat Display;
        }
        &-get-directions {
          font: normal normal 600 15px/20px Red Hat Display;

          .c-icon {
            margin-left: 8px;
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .addresses {
    .adress {
      height: fit-content;

      &-image {
        height: 400px;
        height: fit-content;
        min-height: fit-content;
        margin: 15px 0;

        img {
          height: fit-content;
        }
      }

      &-content {
        height: fit-content;
        min-height: fit-content;
        margin-top: 1rem;

        &-adress {
          width: 100%;
        }
      }
    }

    &.single{
      .adress{
      display:flex;
      flex-direction: column;
  
        &-image {
          flex: auto;
          margin-top: 24px;
        }
      }
    }
  }
}
