.detail {
  padding: 0;
  padding-top: 215px;
  padding-bottom: 50px;
}

@media #{$media-sm} {
  .detail {
    padding-top: 135px;
    padding-bottom: 25px;
  }
}
