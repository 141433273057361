.work-hero {
  &__item {
    height: 100vh;
    position: relative;
    figure {
      position: fixed;
      bottom: 0;
      right: 0;
      height: auto;
      width: auto;
      min-height: 100%;
      min-width: 100%;
      z-index: -9999;
      .image {
        width: 100%;
        height: 100%;
        position: absolute;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &__detail {
      position: absolute;
      width: 100%;
      left: 0;
      top: 197px;
      color: light-neutral(0);
      .col {
        margin: 0 !important;
      }
      .container {
        border-bottom: 1px solid light-neutral(0);
        padding: 25px 0;
      }
      h1 {
        font: normal normal normal 45px/55px $font-family-1;
        letter-spacing: 0px;
        margin: 0;
        margin-bottom: 1rem;
      }
      .infos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font: normal normal 600 25px/33px $font-family-2;
        letter-spacing: -1.25px;

        .brands {
          &::after {
            content: '/';
            display: inline-block;
            height: 100%;
            margin: 0 0.3rem;
          }
        }

        .categories {
          & > span:not(:last-child)::after {
            content: ',';
            margin-right: 0.3rem;
          }
        }
      }
      .contacts {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .hero__dots {
    position: absolute;
    right: 5%;
    bottom: 0;
    z-index: 1;
    padding: 65px 15px;
    display: flex;
    align-items: flex-end;
    .hero__dot {
      padding: 0;
      button {
        padding: 0 5px;
        min-width: 1px;
        box-shadow: none;
        outline: none;
        background: transparent;
        &::before {
          content: '';
          height: 40px;
          width: 1px;
          background-color: light-neutral(0);
        }
      }
      &--active {
        button {
          &::before {
            height: 111px;
          }
        }
      }
      &:last-child {
        button {
          padding-right: 0;
        }
      }
    }
  }
}

@media #{$media-lg} {
  .work-hero {
    &__item {
      &__detail {
        top: 270px;
      }
    }
  }
}

@media #{$media-sm} {
  .work-hero {
    &__item {
      &__detail {
        top: 145px;

        .container {
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: none;
          h1 {
            font: normal normal normal 28px/32px $font-family-1;
            letter-spacing: 0px;
            border-bottom: 1px solid light-neutral(0);
            padding-bottom: 15px;
            margin-bottom: 15px;
          }

          .infos {
            font: normal normal normal 15px/20px $font-family-2;
            letter-spacing: -0.75px;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 8px;
          }

          .contacts {
            justify-content: flex-start;
            margin-top: 15px !important;
          }
        }
      }
    }
  }
}
