.socials {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  .media {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 18px;
    width: max-content;
  }
  &-mail {
    font: normal normal normal 25px/33px Red Hat Display;
    margin-left: 24px;
  }
}


@media #{$media-sm} {
  .socials {
    .media {
      justify-content: flex-start;
      column-gap: 12px;
      margin-top: 12px;
    }
    &-mail {
      font: normal normal normal 15px/20px Red Hat Display;
      margin-left: 12px;
    }
  }
}
