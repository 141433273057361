@keyframes filterContent {
  from {
    transform: translateY(-40%);
    pointer-events: none;
    opacity: 0;
    max-height: 0px;
  }

  to {
    transform: translateY(0);
    pointer-events: auto;
    opacity: 1;
    max-height: 2000px;
  }
}

@keyframes reversefilterContent {
  from {
    transform: translateY(0);
    pointer-events: auto;
    opacity: 1;
    max-height: 2000px;
  }

  to {
    transform: translateY(-40%);
    pointer-events: none;
    opacity: 0;
    max-height: 0px;
  }
}

.filters {
  // border-top: 1px solid dark-neutral(900);
  padding-top: 0.4rem;
  padding-bottom: 1rem;
  position: relative;

  &-header {
    display: flex;
    flex-direction: row;
    column-gap: 5rem;

    .button {
      font: normal normal 300 45px/55px PP Neue Machina;
      letter-spacing: -2.25px;
      padding-left: 0;
      padding-right: 0;
      color: dark-neutral(60);

      &.active {
        color: dark-neutral(0);
        .c-icon {
          -webkit-transform: rotate3d(0, 0, 0, 180deg);
          transform: rotate3d(0, 0, 0, 180deg);
        }
      }

      .c-icon {
        margin-left: 12px;
        -webkit-transform: rotate3d(1, 0, 0, 180deg);
        transform: rotate3d(1, 0, 0, 180deg);
        -webkit-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-timing-function: ease-in-out;
      }
    }
  }

  &-content {
    border-bottom: 1px solid dark-neutral(0);

    &-buttons {
      position: relative;
      display: flex;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 20px;
      padding-top: 1.2rem;
      padding-bottom: 1.3rem;

      width: 70%;

      transform: translateY(-70%);
      pointer-events: none;
      opacity: 0;
      max-height: 0px;

      animation: reversefilterContent 500ms ease-in-out backwards;

      &.active {
        animation: filterContent 500ms ease-in-out forwards;
      }

      .button {
        font: normal normal normal 25px/33px Red Hat Display;
        letter-spacing: -1.25px;
        padding: 6px 30px;
        transition-duration: 250ms;
        transition-timing-function: ease-in-out;
        border-radius: 38px !important;

        &.button--standard-default {
          border: 1px solid dark-neutral(70);
          background-color: transparent;
          color: dark-neutral(70);
        }

        // &:not(.selected):hover {
        //   color: dark-neutral(70);
        // }

        &.selected {
          color: primary(500);
          border: 1px solid primary(500);
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  &-bottom {
    padding: 1rem 0;
    border-bottom: 1px solid dark-neutral(100);

    &-text {
      display: block;
      font: normal normal normal 18px/22px PP Neue Machina;
    }

    &-selects {
      &-text {
        font: normal normal normal 45px/55px PP Neue Machina;

        &:not(:last-child)::after {
          content: ',';
          display: inline-block;
          margin-right: 12px;
        }

        &.brands-end {
          &::after {
            content: '/';
            display: inline-block;
            margin-left: 8px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.projects {
  row-gap: 32px;
  margin-bottom: 3rem;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 2rem;

  .col {
    padding: 0 7px;
  }

  .project-card {
    &-media {
      width: 100%;
      height: 576px;
    }

    &-content {
      margin-top: 1rem;
      &-title {
        font: normal normal normal 45px/55px PP Neue Machina;
        margin-bottom: 12px;
        border-bottom: 2px solid dark-neutral(100);
      }

      &-categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font: normal normal 600 25px/33px Red Hat Display;
        color: dark-neutral(80);

        &-brands {
          span {
            display: flex;
            &:not(:last-child)::after {
              content: ',';
              display: inline-block;
              margin-right: 4px;
            }

            &:last-child::after {
              content: '/';
              display: inline-block;
              margin-left: 8px;
              margin-right: 4px;
            }
          }
        }

        &-categories {
          span {
            &:not(:last-child)::after {
              content: '-';
              display: inline-block;
              margin-left: 4px;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}

.no-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  .text {
    font: normal normal 300 32px/40px PP Neue Machina;
  }
}

.filters-mobile {
  display: none;
  text-align: end;
}

.is-load-button {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   height: fit-content;
   column-gap: 8px;
   border: 0;
   border-radius: 4px;
   margin: auto;
   padding: 11px 24px 8px 24px;
   background-color: transparent;
   cursor: pointer;
   transition: all 150ms ease-in-out;
   box-shadow: 0 0 0 2px var(--DN200);


   &:hover{
    background-color: var(--DN300);
   }

   span{
    font: normal normal 300 18px/18px PP Neue Machina;
    color: var(--DN50);
   }

   .c-icon{
    font-size: 24px;
   }
}

@media #{$media-xl} {
  .filters {
    &-header {
      .button {
        font: normal normal 300 38px/46px PP Neue Machina;
      }
    }

    &-content {
      &-buttons {
        width: 80%;
        .button {
          font: normal normal normal 22px/34px Red Hat Display;
        }
      }
    }

    &-bottom {
      &-selects {
        &-text {
          font: normal normal normal 38px/52px PP Neue Machina;
        }
      }
    }
  }

  .projects {
    .project-card {
      &-media {
        height: 376px;
      }

      &-content {
        &-title {
          font: normal normal normal 28px/31px PP Neue Machina;
          padding-bottom: 8px;
        }
        &-categories {
          font: normal normal normal 20px/20px Red Hat Display;
          letter-spacing: -0.75px;
        }
      }
    }
  }
}

@media #{$media-lg} {
  .filters {
    &-header {
      .button {
        font: normal normal 300 32px/38px PP Neue Machina;
      }
    }

    &-content {
      &-buttons {
        .button {
          font: normal normal normal 20px/28px Red Hat Display;
        }
      }
    }

    &-bottom {
      &-selects {
        &-text {
          font: normal normal normal 32px/48px PP Neue Machina;
        }
      }
    }
  }

  .projects {
    .project-card {
      &-media {
        height: 376px;
      }
    }
  }
}

@media #{$media-md} {
  .projects {
    .project-card {
      &-media {
        height: 276px;
      }

      &-content {
        &-title {
          font: normal normal normal 25px/31px PP Neue Machina;
          padding-bottom: 8px;
        }
        &-categories {
          font: normal normal normal 16px/20px Red Hat Display;
          letter-spacing: -0.75px;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .projects {
    row-gap: 1px;
    margin-top: 0;
  }

  .detail {
    .projects {
      .col {
        padding: 15px 0;
      }
    }
  }

  .filters {
    position: absolute;
    width: 100%;
    max-height: 0;
    min-height: 0;
    background-color: primary(50);
    top: 150px;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 1;
    padding: 0 8%;
    transition: all, 0.5s;

    &-header {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 8px;
      max-height: 200px;
      margin-top: 30px;

      .button {
        font: normal normal normal 25px/31px PP Neue Machina;
        letter-spacing: 0px;
        line-height: 50px;
        animation-name: reversefloatBubblev2;
        animation-duration: 1s;
        animation-fill-mode: backwards;
        min-height: auto;
      }
    }

    &-content {
      padding: 15px 0;
      margin-bottom: 30px;
      &-buttons {
        // flex-direction: column;
        width: 100%;
        padding: 0;

        .button {
          padding: 0;
          font: normal normal normal 18px/20px Red Hat Display;
          letter-spacing: -0.75px;
          width: 100%;
        }
      }
    }

    &-mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      position: relative;
      z-index: 3;

      .c-icon {
        margin-left: 12px;
        font-size: 35px;

        &.selected {
          color: primary(500);
        }
      }

      .filters-bottom {
        flex: auto;
        text-align: start;
        border-bottom: none;
        padding: 0;
        color: dark-neutral(80);
        transition: all 200ms ease-in-out 630ms;

        &-text {
          display: inline-block;
          font: normal normal normal 12px/15px PP Neue Machina;
          margin-right: 4px;
        }

        &-selects {
          display: inline-block;

          &-text {
            font: normal normal normal 12px/15px PP Neue Machina;

            &:not(:last-child)::after {
              content: ',';
              display: inline-block;
              margin-right: 4px;
            }

            &.brands-end {
              &::after {
                content: '/';
                display: inline-block;
                margin-left: 4px;
                margin-right: 4px;
              }
            }
          }
        }

        &.deactive{
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
          transition: all 100ms ease-in-out;
          transition-delay: 0;
        }
      }
    }

    &.active {
      max-height: 3000px;
      min-height: 100vh;
      pointer-events: auto;
      transition: all, 1s;
      .filters {
        &-header {
          .button {
            animation-name: floatBubblev2;
            animation-duration: 1s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }

  .no-search {
    .text {
      text-align: center;
      font: normal normal 16px/22px PP Neue Machina;
    }
  }
}

@keyframes reversefloatBubblev2 {
  0% {
    opacity: 1;
    top: 0;
    height: 50px;
    overflow: hidden;
  }

  100% {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  100% {
    opacity: 0;
    top: -50px;
    height: 0;
    overflow: hidden;
  }
}

@keyframes floatBubblev2 {
  0% {
    opacity: 0;
    top: -50px;
    margin-bottom: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    top: 0;
    height: 60px;
  }

  100% {
    opacity: 1;
    top: 0;
    height: 50px;
    margin-bottom: 0;
  }
}

@keyframes floatBubbleButton {
  0% {
    opacity: 0;
    margin-bottom: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    height: 30px;
  }

  100% {
    opacity: 1;
    height: 30px;
    margin-bottom: 0;
  }
}
