.language-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  button {
    background: inherit;
    border: none;
    cursor: pointer;
    transition: all 130ms ease-in-out;
    font-size: 16px;
    line-height: 14px;
    color: light-neutral(40);

    &:hover {
      color: primary(500);
    }

    &.current-lang {
      color: primary(500);
    }

    &:not(:last-child) {
      margin-right: 0;
      padding-right: 0;
      &::after {
        position: relative;
        display: inline-block;
        content: '';
        width: 1px;
        height: 12px;
        margin-left: 8px;
        background-color: light-neutral(40);
      }
    }
  }
}
