footer {
  background-color: dark-neutral(900);
  color: dark-neutral(0);
  padding: 6rem 0 4rem 0;
  .footer {
    &-up {
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid dark-neutral(100);
      padding-bottom: 3rem;

      &-nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 48px;
        flex-wrap: wrap;

        &-item {
          font: normal normal normal 44px/44px PP Neue Machina;
          letter-spacing: 0px;
          -webkit-transition: all 200ms ease-in-out;
          -o-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;

          &:hover {
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
      }
    }

    &-adress {
      margin-top: 3rem;
      padding-right: 3rem;
      row-gap: 24px;

      .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 12px;
      }

      &-title {
        font: normal normal normal 42px/48px PP Neue Machina;
        margin: 0;
      }

      &-adress {
        width: 80%;
        font: normal normal normal 25px/33px Red Hat Display;
      }

      &-phone {
        font: normal normal normal 25px/33px Red Hat Display;
      }

      &-get-directions {
        font: normal normal 600 25px/33px Red Hat Display;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 8px;
      }
    }

    &-bottom {
      margin-top: 3rem;

      & > .col:nth-child(2) {
        text-align: end;
      }

      &-infos {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 32px;
        font-family: Red Hat Display;

        span {
          font-size: 12px;
          letter-spacing: -0.18px;
        }

        .copyright {
          margin-left: 6px;
        }

        &-logo {
          img {
            width: 80px;
            height: 50px;
            object-fit: contain;
            object-position: center;
          }
          &-asg {
            display: block;
            width: 100%;
            font-size: 20px !important;
            margin-bottom: 10px;
          }
        }
      }

      &-policy {
        display: flex;
        flex-direction: column;

        &.homepage {
          .language-switch {
            button {
              color: #333333;

              &:not(:last-child) {
                &::after {
                  background-color: #333333;
                }
              }
            }
          }
        }

        .language-switch {
          justify-content: flex-end;
        }

        &-policies {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          column-gap: 12px;

          &-link {
            position: relative;
            font: normal normal normal 12px/16px Red Hat Display;
            margin-top: 0;

            &:not(:last-child) {
              padding-right: 12px;
            }

            &:not(:last-child)::after {
              content: '';
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              width: 1px;
              height: 100%;
              background-color: #bfbfbf;
            }
          }
        }
      }
    }
  }
}

.mobile-logo {
  display: none;
  img {
    width: 64px;
    object-fit: contain;
    object-position: center;
  }
}

@media #{$media-2xl} {
  footer {
    .footer {
      &-up {
        &-nav {
          &-item {
            font: normal normal normal 32px/36px PP Neue Machina;
          }
        }
      }

      &-adress {
        &-title {
          font: normal normal normal 32px/36px PP Neue Machina;
        }
      }
    }
  }
}

@media #{$media-xl} {
  footer {
    .footer {
      &-up {
        row-gap: 24px;
        &-nav {
          justify-content: center;
          &-item {
            font: normal normal normal 32px/36px PP Neue Machina;
          }
        }
        .socials {
          margin: auto;
        }
      }
      &-adress {
        row-gap: 32px;
        &-title {
          font: normal normal normal 32px/31px PP Neue Machina;
        }
        &-adress {
          font: normal normal normal 22px/26px Red Hat Display;
        }
        &-phone {
          font: normal normal normal 22px/26px Red Hat Display;
        }
        &-get-directions {
          font: normal normal 600 20px/26px Red Hat Display;
        }
      }
      &-bottom {
        &-infos {
          &-logo {
            &-asg {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  footer {
    padding: 2rem 0;
    .footer {
      &-up {
        padding-bottom: 1rem;
        row-gap: 8px;
        &-nav {
          row-gap: 8px;
          column-gap: 14px;
          justify-content: flex-start;

          &-item {
            font: normal normal normal 20px/25px PP Neue Machina;
          }
        }
        .socials {
          justify-content: flex-start;
          column-gap: 12px;
          margin-top: 12px;
          &-mail {
            font: normal normal normal 15px/20px Red Hat Display;
            margin-left: 12px;
          }

          .media {
            margin-top: 0;
          }
        }
      }

      &-adress {
        margin-top: 2rem;
        margin-bottom: 3rem;
        padding-right: 0;
        row-gap: 32px;

        .col {
          row-gap: 12px;
        }

        .c-icon {
          font-size: 22px;
        }

        &-title {
          font: normal normal normal 25px/31px PP Neue Machina;
        }
        &-adress {
          font: normal normal normal 15px/20px Red Hat Display;
        }
        &-phone {
          font: normal normal normal 15px/20px Red Hat Display;
        }
        &-get-directions {
          font: normal normal 600 20px/26px Red Hat Display;
        }
      }

      &-bottom {
        margin-top: 1.3rem;
        &-infos {
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
          row-gap: 4px;
          margin-top: 24px;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          span: {
            font-size: 12px;
          }

          &-logo {
            img {
              margin-bottom: 15px;
            }
            &-asg {
              font-size: 16px !important;
              text-align: center;
            }
          }
        }

        &-policy {
          h5 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            font: normal normal normal 15px/20px Red Hat Display;
            margin-bottom: 1.5rem;

            strong {
              font: normal normal bold 15px/20px Red Hat Display;
            }

            .mobile-logo {
              display: none;
            }
          }

          &-policies {
            column-gap: 4px;
            justify-content: center;
            &-link {
              font: normal normal normal 12px/16px Red Hat Display;

              &:not(:last-child) {
                padding-right: 4px;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media-xs} {
  footer {
    .footer {
      &-up {
        .socials {
          width: 100%;
          flex-direction: column;
          row-gap: 12px;
          margin-left: 0;
        }
      }
    }
  }
}
